import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import office from "./images/digsafOH.png";
import tj from "./images/tj.png";
import gaypec from "./images/gaypec.png";
import sutro from "./images/Sutro.png";
import {Cursor} from './Cursor.js';
import AboutPage from './pages/AboutPage.js';
import ProjectPage from './pages/ProjectPage.js';

function App() {
  const [, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <BrowserRouter>
      <Cursor />
      <div
        className="me container text link"
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        role="button"
        tabIndex="-3"
      > {"meltycave"}
      </div>
      <div className="text"> is an illustrator living in san francisco, on unceded Ramaytush Ohlone land <span role="img" aria-label="earth">🌍</span></div>
      <br/>
      <Routes>
        <Route path ="/about" element={<AboutPage />}/>
        <Route path ="/projects" element={<ProjectPage />}/>
      </Routes>
      &#128444;<a className="text" href="https://www.instagram.com/meltycave/">Instagram</a>
      <br/>
      &#128234;<a className="text" href = "mailto: beckmliao@gmail.com">Email</a>
      <br/>
      <h3>LavNix Work</h3>
      
      <img src={sutro} width="500" height="500" alt="Sutro"/>
      <img src={office} width="500" height="500" alt="office hours"/>
      <img src={gaypec} width="500" height="500" alt="gaypec" />
      <img src={tj} width="500" height="500" alt="free palestine means trans justice"/>
      <br/>
      <div className="text">They organize at </div> <a className="text" href="https://lavenderphoenix.org/">Lavender Phoenix</a><div className="text">, creating narrative-driven illustrations for building QTAPI power towards collective liberation <span role="img" aria-label="fire">🔥</span> </div>

    </BrowserRouter>
  );
}

export default App;
